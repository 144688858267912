import React from 'react';
import { AiOutlineUser, AiOutlineClockCircle } from 'react-icons/ai';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';

const TransferredConversation = ({ name = '', date = moment(), id = '', onClick = () => {} }) => {
    const { user } = useAuth();

    const calculateTime = () => {
        let diff = moment().diff(moment(date), 'minutes');
        if (diff < 60) {
            return `${diff} min ago`;
        }
        diff = moment().diff(moment(date), 'hours');
        if (diff < 24) {
            return `${diff} hours ago`;
        }
        diff = moment().diff(moment(date), 'days');
        return `${diff} days ago`;
    };

    const click = async () => {
        try {
            await axios.put(
                `/api/v1/notifications/${id}`,
                { seen: true },
                {
                    headers: {
                        'X-Auth-Token': user.token,
                    },
                }
            );
            onClick();
        } catch (err) {}
    };

    return (
        <div onClick={click} className='text-reset notification-item d-block dropdown-item position-relative'>
            <div className='d-flex'>
                <div className='avatar-xs me-3'>
                    <span className='avatar-title bg-soft-info text-info rounded-circle fs-16'>
                        <AiOutlineUser />
                    </span>
                </div>
                <div className='flex-1'>
                    <a className='stretched-link'>
                        <h6 className='mt-0 mb-1 fs-13 fw-semibold'>{name}</h6>
                    </a>
                    <div className='fs-13 text-muted'>
                        <p className='mb-1'>Was transferred to you.</p>
                    </div>
                    <p className='mb-0 fs-11 fw-medium text-uppercase text-muted icon-mg-6'>
                        <span>
                            <AiOutlineClockCircle />
                            {calculateTime()}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TransferredConversation;
