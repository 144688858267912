import React from 'react';
import { NavLink } from 'react-router-dom';

/*
example with pill

<li className='nav-item'>
    <a href='dashboard-job.html' className='nav-link'>
        <span data-key='t-job'>Job</span>
        <span className='badge badge-pill bg-success' data-key='t-new'>
            New
        </span>
    </a>
</li>
*/

const SubMenuItem = ({ children, to = '/' }) => {
    return (
        <li className='nav-item'>
            <NavLink to={to} className='nav-link'>
                {children}
            </NavLink>
        </li>
    );
};

export default SubMenuItem;
