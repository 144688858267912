import React from 'react';
import TopBarNotifications from './Notifications/TopBarNotifications';

const TopBarModules = () => {
    return (
        <div className='d-flex align-items-center'>
            <TopBarNotifications />
        </div>
    );
};

export default TopBarModules;
