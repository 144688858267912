import React from 'react';

const SubMenu = ({ children }) => {
    return (
        <ul className='nav nav-sm flex-column'>
            {children}
        </ul>
    );
};

export default SubMenu;
