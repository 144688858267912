import React, { Fragment, useEffect, useState } from 'react';
import { RiSearchLine, RiAddLine } from 'react-icons/ri';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ContactEntry from './ContactEntry';

const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [syncing, setSyncing] = useState(false);

    const fetchContacts = async () => {
        try {
            const response = await axios.get('/api/v1/contacts', {
                headers: {
                    'X-Auth-Token': user.token,
                },
            });
            setContacts(response.data);
            setLoading(false);
            setSyncing(false);
        } catch (err) {}
    };

    const syncContacts = async () => {
        setSyncing(true);
        setLoading(true);

        try {
            await axios.post(
                '/api/v1/contacts/sync',
                {},
                {
                    headers: {
                        'X-Auth-Token': user.token,
                    },
                }
            );
            fetchContacts();
        } catch (err) {}
    };

    useEffect(() => {
        fetchContacts();
    }, []);

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>Contact List</h4>
                    </div>
                </div>
            </div>

            <div className='row g-4 mb-4'>
                <div className='col-sm-auto'>
                    <div>
                        <Link to='#' className='btn btn-success disabled'>
                            <RiAddLine className='me-1' /> Add Contact (TODO)
                        </Link>
                    </div>
                </div>
                <div className='col-sm-auto'>
                    <div>
                        <button onClick={syncContacts} className='btn btn-success' disabled={syncing}>
                            Sync Contacts
                        </button>
                    </div>
                </div>
                <div className='col-sm'>
                    <div className='d-md-flex justify-content-sm-end gap-2'>
                        <div className='search-box ms-md-2 flex-shrink-0 mb-3 mb-md-0'>
                            <input
                                type='text'
                                className='form-control'
                                autoComplete='off'
                                placeholder='Search Contact...'
                            />
                            <RiSearchLine className='search-icon' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row gy-2 mb-2' id='candidate-list'>
                {loading ? (
                    <div className='text-center'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                ) : (
                    contacts.map((item) => <ContactEntry key={item._id} item={item} />)
                )}
            </div>
        </Fragment>
    );
};

export default Contacts;
