import React from 'react';
import logoSm from '../assets/img/logo-sm.png';
import logoDark from '../assets/img/logo-dark.png';
import logoLight from '../assets/img/logo-light.png';

const TopBarLogo = () => {
    return (
        <div className='navbar-brand-box horizontal-logo'>
            <a className='logo logo-dark'>
                <span className='logo-sm'>
                    <img src={logoSm} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                    <img src={logoDark} alt='' height='17' />
                </span>
            </a>

            <a className='logo logo-light'>
                <span className='logo-sm'>
                    <img src={logoSm} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                    <img src={logoLight} alt='' height='17' />
                </span>
            </a>
        </div>
    );
};

export default TopBarLogo;
