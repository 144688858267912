import React from 'react';
import { Link } from 'react-router-dom';

const ContactEntry = ({ item }) => {
    return (
        <div className='col-md-6 col-lg-12'>
            <div className='card mb-0'>
                <div className='card-body'>
                    <div className='d-lg-flex align-items-center'>
                        <div className='flex-shrink-0'>
                            <div className='avatar-sm rounded'>
                                <div className='avatar-title border bg-light text-primary rounded text-uppercase fs-16'>
                                    {item.vCard.fn && item.vCard.fn[0].value.substring(0, 2)}
                                </div>
                            </div>
                        </div>
                        <div className='ms-lg-3 my-3 my-lg-0'>
                            <Link to={`/contacts/${item._id}`}>
                                <h5 className='fs-16 mb-2'>{item.vCard.fn && item.vCard.fn[0].value}</h5>
                            </Link>
                            <p className='text-muted mb-0'>{item.vCard.tel && item.vCard.tel[0].value}</p>
                        </div>
                        <div className='d-flex gap-4 mt-0 text-muted mx-auto'>
                            <div>
                                {item.status === 'new' && <span className='badge badge-soft-secondary'>New</span>}
                                {item.status === 'premium' && <span className='badge badge-soft-success'>Premium</span>}
                                {item.status === 'blocked' && <span className='badge badge-soft-danger'>Blocked</span>}
                            </div>
                        </div>
                        <div>
                            <Link to={`/contacts/${item._id}`} className='btn btn-soft-success'>
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactEntry;
