import React from 'react';
import Footer from '../parts/Footer';
import MainContent from '../parts/MainContent';
import Sidebar from '../parts/Sidebar';
import TopBar from '../parts/TopBar';
import { Routes, Route } from 'react-router-dom';
import Contacts from '../modules/Contacts/Contacts';
import Contact from '../modules/Contacts/Contact';
import Calendar from '../modules/Calendar/Calendar';
import Appointment from '../modules/Calendar/Appointment';

const Dashboard = () => {
    return (
        <div id='layout-wrapper'>
            <TopBar />
            <Sidebar />
            <MainContent>
                <Routes>
                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/contacts/:id' element={<Contact />} />
                    <Route path='/appointment/:id' element={<Appointment />} />
                    <Route path='/calendar' element={<Calendar />} />
                </Routes>
                <Footer />
            </MainContent>
        </div>
    );
};

export default Dashboard;
