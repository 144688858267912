import React, { useEffect, useState } from 'react';
import { RiBookletLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment';
import Note from './Note';

const Notes = ({ id }) => {
    const { user } = useAuth();
    const [notes, setNotes] = useState([]);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(true);

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/v1/contacts/${id}/notes`, {
                headers: { 'X-Auth-Token': user.token },
            });
            setNotes(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        if (text !== '') {
            setLoading(true);
            try {
                await axios.post(
                    `/api/v1/contacts/${id}/notes`,
                    { text: text },
                    {
                        headers: { 'X-Auth-Token': user.token },
                    }
                );
                setText('');
                fetch();
            } catch (err) {
                setLoading(false);
            }
        }

        return false;
    };

    const change = (e) => {
        setText(e.target.value);
    };

    return (
        <div className='card'>
            <div className='card-header'>
                <ul className='nav nav-tabs-custom rounded card-header-tabs border-bottom-0' role='tablist'>
                    <li className='nav-item' role='presentation'>
                        <span className='nav-link active'>
                            <RiBookletLine /> Notes
                        </span>
                    </li>
                </ul>
            </div>
            <div className='card-body p-4'>
                <div className='tab-content'>
                    <div className='tab-pane active'>
                        <div className='row gy-2 mb-2'>
                            <form onSubmit={submit}>
                                <div className='mb-3'>
                                    <textarea
                                        onChange={change}
                                        className='form-control'
                                        name='text'
                                        rows='6'
                                        value={text}
                                    />
                                </div>
                                <button disabled={loading} type='submit' className='btn btn-success'>
                                    Add New
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer'>
                {loading ? (
                    <div className='text-center'>
                        <div className='spinner-border text-primary' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='acitivity-timeline'>
                        {notes.map((item) => (
                            <Note key={item._id} item={item} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Notes;
