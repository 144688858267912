import React from 'react';
import moment from 'moment';

const Note = ({ item }) => {
    return (
        <div className='acitivity-item d-flex mb-3'>
            <div className='flex-shrink-0'>
                <div className='avatar-xxs'>
                    <div className='avatar-title rounded-circle bg-primary text-white fs-10'>
                        {item.createdBy.username.substring(0, 2).toUpperCase()}
                    </div>
                </div>
            </div>
            <div className='flex-grow-1 ms-3'>
                <p className='text-muted mb-2' dangerouslySetInnerHTML={{ __html: item.text }}></p>
                <small className='mb-0 text-muted'>{moment(item.created).format('DD.MM.YYYY HH:mm')}</small>
            </div>
        </div>
    );
};

export default Note;
