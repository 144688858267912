import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import Flatpickr from 'react-flatpickr';
import { MdAdd } from 'react-icons/md';
import moment from 'moment';

const Appointment = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [state, setState] = useState({
        customer: {
            nickname: '',
        },
        host: {
            username: '',
        },
    });

    const [formState, setFormState] = useState({
        duration: '00:00',
        notes: '',
        date: new Date(),
    });

    const fetchConversation = async () => {
        try {
            const response = await axios.get(`/api/v1/conversations/${id}`, {
                headers: {
                    'X-Auth-Token': user.token,
                },
            });
            setState(response.data);
        } catch (err) {}
    };

    const changeTime = (e) => {
        const time = e.target.valueAsNumber / 1000;
        const hours = Math.floor(time / 3600);

        let minutes = (time % (hours * 3600)) / 60;
        if (hours == 0) {
            minutes = time / 60;
        }

        const timeString = String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
        setFormState({ ...formState, duration: timeString });
    };

    const change = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchConversation();
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`/api/v1/conversations/${id}/appointment`, formState, {
                headers: {
                    'X-Auth-Token': user.token,
                },
            });
            navigate(`/chat/${id}`, { replace: true });
        } catch (err) {}
        return false;
    };

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>Appointments</h4>
                    </div>
                </div>
            </div>
            <div className='row'>
                <form onSubmit={submit}>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h4 className='card-title mb-0'>
                                    {state.customer.nickname} -&gt; {state.host.username}
                                </h4>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label class='form-label'>Date</label>
                                        <Flatpickr
                                            className='form-control'
                                            data-enable-time
                                            options={{ time_24hr: true, minuteIncrement: 1 }}
                                            value={formState.date}
                                            onChange={([date]) => {
                                                setFormState({ ...formState, date: date });
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <div>
                                            <label class='form-label'>Duration</label>
                                            <input
                                                type='time'
                                                value={formState.duration}
                                                class='form-control'
                                                onChange={changeTime}
                                                name='duration'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <label class='form-label'>Notes</label>
                                        <textarea
                                            class='form-control'
                                            rows='10'
                                            value={formState.notes}
                                            name='notes'
                                            onChange={change}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <div class='hstack gap-2 justify-content-end'>
                                            <button type='submit' class='btn btn-success'>
                                                <MdAdd /> Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default Appointment;
