import React, { useRef } from 'react';
import $ from 'jquery';

const NavCollapsable = ({ icon, title = '', children }) => {
    const linkRef = useRef();
    const collapseRef = useRef();

    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };
    return (
        <li className='nav-item'>
            <a
                className='nav-link menu-link collapsed'
                data-bs-toggle='collapse'
                role='button'
                aria-expanded='false'
                ref={linkRef}
                onClick={click}
            >
                {icon}
                <span data-key='t-dashboards'>{title}</span>
            </a>
            <div className='collapse menu-dropdown' ref={collapseRef}>
                {children}
            </div>
        </li>
    );
};

export default NavCollapsable;
