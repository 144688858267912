import React, { Fragment, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { AiOutlineBell } from 'react-icons/ai';
import { useSocket } from '../../hooks/useSocket';
import { useAuth } from '../../hooks/useAuth';
import StartedConversation from './StartedConversation';
import { RiArrowRightLine } from 'react-icons/ri';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import TransferredConversation from './TransferredConversation';
import MadeAppointment from './MadeAppointment';

const TopBarNotifications = () => {
    const buttonRef = useRef();
    const dropdownRef = useRef();
    const { socket } = useSocket();
    const { user } = useAuth();
    const [state, setState] = useState({
        count: 0,
        data: [],
    });

    const navigate = useNavigate();

    const click = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (buttonRef.current.classList.contains('show')) {
            buttonRef.current.classList.remove('show');
            dropdownRef.current.classList.remove('show');
        } else {
            buttonRef.current.classList.add('show');
            dropdownRef.current.classList.add('show');
        }
    };

    const dontClose = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    const fetch = async () => {
        try {
            const response = await axios.get('/api/v1/notifications', {
                headers: {
                    'X-Auth-Token': user.token,
                },
            });
            setState(response.data);
        } catch (err) {}
    };

    useEffect(() => {
        fetch();
        socket.on(`${user.id}/notification`, () => {
            fetch();
        });

        return () => {
            socket.off(`${user.id}/notification`);
        };
    }, []);

    const fetchAndRedirect = async (id) => {
        fetch();
        $('.dropdown-menu').removeClass('show');
        $('.btn-topbar').removeClass('show');
        navigate(`/chat/${id}`, { replace: true });
    };

    return (
        <div className='dropdown topbar-head-dropdown ms-1 header-item' id='notificationDropdown'>
            <button
                type='button'
                className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
                id='page-header-notifications-dropdown'
                data-bs-toggle='dropdown'
                data-bs-auto-close='outside'
                aria-haspopup='true'
                aria-expanded='false'
                ref={buttonRef}
                onClick={click}
            >
                <AiOutlineBell className='fs-22' />
                {state.count > 0 && (
                    <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
                        {state.count}
                        <span className='visually-hidden'>unread notifications</span>
                    </span>
                )}
            </button>
            <div
                className='dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                aria-labelledby='page-header-notifications-dropdown'
                ref={dropdownRef}
                onClick={dontClose}
            >
                <div className='dropdown-head bg-primary bg-pattern rounded-top'>
                    <div className='p-3'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='m-0 fs-16 fw-semibold text-white'>Notifications</h6>
                            </div>
                            <div className='col-auto dropdown-tabs'>
                                <span className='badge badge-soft-light fs-13'> {state.count} New</span>
                            </div>
                        </div>
                    </div>

                    <div className='px-2 pt-2'>
                        <ul
                            className='nav nav-tabs dropdown-tabs nav-tabs-custom'
                            data-dropdown-tabs='true'
                            id='notificationItemsTab'
                            role='tablist'
                        >
                            <li className='nav-item waves-effect waves-light' role='presentation'>
                                <a className='nav-link active' data-bs-toggle='tab' role='tab' aria-selected='true'>
                                    Newest
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='tab-content position-relative' id='notificationItemsTabContent'>
                    <div className='tab-pane fade py-2 ps-2 active show' id='all-noti-tab' role='tabpanel'>
                        <SimpleBar style={{ maxHeight: 300 }}>
                            {state.data.map((item) => (
                                <Fragment>
                                    {item.type === 'customer_started_conversation' && (
                                        <StartedConversation
                                            key={item._id}
                                            name={item.customer.nickname}
                                            date={item.created}
                                            id={item._id}
                                            onClick={(e) => {
                                                fetchAndRedirect(item.conversation);
                                            }}
                                        />
                                    )}
                                    {item.type === 'transferred_conversation' && (
                                        <TransferredConversation
                                            key={item._id}
                                            name={item.customer.nickname}
                                            date={item.created}
                                            id={item._id}
                                            onClick={(e) => {
                                                fetchAndRedirect(item.conversation);
                                            }}
                                        />
                                    )}
                                    {item.type === 'moderator_made_appointment' && (
                                        <MadeAppointment
                                            key={item._id}
                                            name={item.customer.nickname}
                                            date={item.created}
                                            id={item._id}
                                        />
                                    )}
                                </Fragment>
                            ))}

                            <div className='my-3 text-center view-all' style={{ display: 'block' }}>
                                <button type='button' className='btn btn-soft-success waves-effect waves-light'>
                                    View All Notifications
                                    <RiArrowRightLine className='align-middle' />
                                </button>
                            </div>
                        </SimpleBar>
                    </div>

                    <div className='notification-actions' id='notification-actions'>
                        <div className='d-flex text-muted justify-content-center'>
                            Select
                            <div id='select-content' className='text-body fw-semibold px-1'>
                                0
                            </div>
                            Result
                            <button
                                type='button'
                                className='btn btn-link link-danger p-0 ms-3'
                                data-bs-toggle='modal'
                                data-bs-target='#removeNotificationModal'
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBarNotifications;
