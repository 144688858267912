import { ProvideAuth } from "./hooks/useAuth";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Protected from "./components/Protected";
import { ProvideSocket } from "./hooks/useSocket";
import Dashboard from "./pages/Dashboard";
import $ from "jquery";
import { useEffect } from "react";

function App() {
    useEffect((e) => {
        document.body.addEventListener("click", function (e) {
            $(".dropdown-menu").removeClass("show");
            $(".btn-topbar").removeClass("show");
            $(".dd-link").removeClass("show");
            $(".btn-dropdown").removeClass("show");
        });
    }, []);

    return (
        <ProvideAuth>
            <Router>
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route
                        path='/*'
                        element={
                            <Protected>
                                <ProvideSocket>
                                    <Dashboard />
                                </ProvideSocket>
                            </Protected>
                        }
                    />
                </Routes>
            </Router>
        </ProvideAuth>
    );
}

export default App;
