import React, { Fragment, useEffect, useState } from 'react';
import avatar from '../../assets/img/default-avatar.webp';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { MdAdd } from 'react-icons/md';
import Notes from './Notes';
import $ from 'jquery';

const Contact = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [contact, setContact] = useState({
        status: 'new',
        vCard: {}
    });
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        nickname: '',
        number: '',
    });

    const fetchContact = async () => {
        if (id !== 'add') {
            try {
                const response = await axios.get(`/api/v1/contacts/${id}`, {
                    headers: {
                        'X-Auth-Token': user.token,
                    },
                });
                setContact(response.data);
            } catch (err) {}
        }
    };

    useEffect(() => {
        fetchContact();
    }, []);

    const change = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        /*
        if (id === 'add') {
            try {
                const response = await axios.post(`/api/v1/contacts`, contact, {
                    headers: {
                        'X-Auth-Token': user.token,
                    },
                });
                navigate(`/contacts/${response.data.id}`, { replace: true });
            } catch (err) {
                if (err.response.data.hasErrors !== undefined && err.response.data.hasErrors) {
                    setErrors(err.response.data.errors);
                }
            }
        } else {
            try {
                const response = await axios.put(`/api/v1/contacts/${id}`, contact, {
                    headers: {
                        'X-Auth-Token': user.token,
                    },
                });
                $('.alert-fade').show();
                $('.alert-fade').fadeOut({duration: 3000});
            } catch (err) {
                if (err.response.data.hasErrors !== undefined && err.response.data.hasErrors) {
                    setErrors(err.response.data.errors);
                }
            }
        }
        */
        return false;
    };

    return (
        <Fragment>
            <div className='position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg profile-setting-img'>
                    <div className='overlay-content'></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-3'>
                    <div className='card mt-n5'>
                        <div className='card-body p-4'>
                            <div className='text-center'>
                                <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                                    <img
                                        src={avatar}
                                        className='rounded-circle avatar-xl img-thumbnail user-profile-image'
                                    />
                                </div>
                                <h5 className='fs-16 mb-1'>{contact.vCard.fn && contact.vCard.fn[0].value}</h5>
                                <p className='text-muted mb-0'>{contact.vCard.tel && contact.vCard.tel[0].value}</p>
                            </div>
                        </div>
                    </div>
                    <div className='card card-h-100'>
                        <div className='card-body'>
                            <button className='btn btn-primary w-100 disabled' id='btn-new-event'>
                                <MdAdd /> Make Appointment (TODO)
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-xxl-9'>
                    <div className='card mt-xxl-n5'>
                        <div className='card-header'>
                            <ul className='nav nav-tabs-custom rounded card-header-tabs border-bottom-0' role='tablist'>
                                <li className='nav-item' role='presentation'>
                                    <span className='nav-link active'>
                                        <i className='fas fa-home'></i> Customer Details
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className='card-body p-4'>
                            <div className='tab-content'>
                                <div className='tab-pane active'>
                                    <form onSubmit={submit}>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='mb-3'>
                                                    <label htmlFor='statusSelect' className='form-label'>
                                                        Status
                                                    </label>
                                                    <select
                                                        onChange={change}
                                                        id='statusSelect'
                                                        name='status'
                                                        className='form-select'
                                                        value={contact.status}
                                                    >
                                                        <option value='new'>New</option>
                                                        <option value='premium'>Premium</option>
                                                        <option value='blocked'>Blocked</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div
                                                    className='alert alert-success alert-fade'
                                                    style={{ display: 'none' }}
                                                    role='alert'
                                                >
                                                    <strong> Success! </strong> Contact was updated.
                                                </div>
                                            </div>

                                            <div className='col-lg-12'>
                                                <div className='hstack gap-2 justify-content-end'>
                                                    <button type='submit' className='btn btn-primary'>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {id !== 'add' && <Notes id={id} />}
                </div>
            </div>
        </Fragment>
    );
};

export default Contact;
