import React from 'react';
import SidebarLogo from './SidebarLogo';
import { RiDashboard2Line } from 'react-icons/ri';
import SimpleBar from 'simplebar-react';
import SidebarTitle from './SidebarTitle';
import NavCollapsable from './NavCollapsable';
import SubMenu from './SubMenu';
import SubMenuItem from './SubMenuItem';
import { useAuth } from '../hooks/useAuth';

const Sidebar = () => {
    const { user } = useAuth();

    return (
        <div className='app-menu navbar-menu'>
            <SidebarLogo />
            <ul className='navbar-nav'>
                <SimpleBar className='navbar-nav'>
                    <SidebarTitle>Menu</SidebarTitle>
                    <NavCollapsable icon={<RiDashboard2Line />} title='Dashboard'>
                        <SubMenu>
                            {user.role === 'moderator' && <SubMenuItem to='/contacts'>Contacts</SubMenuItem>}
                            <SubMenuItem to='/calendar'>Calendar</SubMenuItem>
                        </SubMenu>
                    </NavCollapsable>
                </SimpleBar>
            </ul>

            <div className='sidebar-background'></div>
        </div>
    );
};

export default Sidebar;
