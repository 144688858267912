import React, { useState, useContext, createContext } from 'react';
import axios from 'axios';

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const login = async (state) => {
        try {
            const response = await axios.post('/api/v1/auth', state);
            setUser(response.data);
        } catch (err) {
            throw err;
        }
    };

    const signup = (email, password) => {};
    const signout = () => {};
    const sendPasswordResetEmail = (email) => {};
    const confirmPasswordReset = (code, password) => {};

    return {
        user,
        login,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
    };
}
