import React, { Fragment, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment';

const Calendar = () => {
    const { user } = useAuth();
    const [appointments, setAppointments] = useState([]);
    const [events, setEvents] = useState([]);

    const fetch = async () => {
        try {
            const response = await axios.get(`/api/v1/appointments`, {
                headers: {
                    'X-Auth-Token': user.token,
                },
            });
            setAppointments(response.data);
            const newEvents = [];
            response.data.map((item) => {
                const splits = item.duration.split(':');
                let hours = parseInt(splits[0]);
                let minutes = parseInt(splits[1]);
                const start = moment(item.date);
                const end = moment(item.date).add(hours, 'hour').add(minutes, 'minute');
                newEvents.push({
                    title: `${item.customer.nickname} with ${item.host.username}`,
                    start: start.format('YYYY-MM-DDTHH:mm:ss'),
                    end: end,
                });
            });
            setEvents(newEvents);
        } catch (err) {}
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>Calendar</h4>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-xl-3'>
                            <div>
                                <h5 className='mb-1'>Upcoming Events</h5>
                                <p className='text-muted'>Don't miss scheduled events</p>
                                <SimpleBar className='pe-2 me-n1 mb-3'>
                                    <div id='upcoming-event-list'>
                                        {appointments.map((item) => (
                                            <div key={item._id} className='card mb-3'>
                                                <div className='card-body'>
                                                    <div className='d-flex mb-3'>
                                                        <div className='flex-grow-1'>
                                                            <i className='mdi mdi-checkbox-blank-circle me-2 text-info'></i>
                                                            <span className='fw-medium'>
                                                                {moment(item.date).format('YYYY-MM-DD')}
                                                            </span>
                                                        </div>
                                                        <div className='flex-shrink-0'>
                                                            <small className='badge badge-soft-primary ms-auto'>
                                                                {moment(item.date).format('HH:mm')}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <h6 className='card-title fs-16'>
                                                        {item.customer.nickname} with {item.host.username}
                                                    </h6>
                                                    <p className='text-muted text-truncate-two-lines mb-0'></p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </SimpleBar>
                            </div>
                        </div>
                        <div className='col-xl-9'>
                            <div className='card card-h-100'>
                                <div className='card-body'>
                                    <FullCalendar
                                        headerToolbar={{
                                            start: 'title',
                                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                                            end: 'today prev,next',
                                        }}
                                        events={events}
                                        themeSystem='bootstrap'
                                        plugins={[dayGridPlugin, timeGridPlugin, bootstrapPlugin]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Calendar;
