import React from 'react';

const SidebarTitle = ({ children }) => {
    return (
        <li className='menu-title'>
            <span data-key='t-menu'>{children}</span>
        </li>
    );
};

export default SidebarTitle;
