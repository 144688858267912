import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/fonts.css';
import 'simplebar-react/dist/simplebar.min.css';
import './assets/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/css/calendar.css';
import './assets/css/app.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
